@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	body {
		@apply bg-gray-50 text-black dark:bg-gray-800 dark:text-white;
		@apply min-h-screen;
	}

	/* React-Select Custom CSS Classes - Searchable Selector */
	.searchable-select-container .searchable-select__control {
		@apply w-full rounded-md shadow-sm sm:text-sm text-gray-800 dark:text-white dark:bg-gray-800 py-1;
	}

	.searchable-select-container .searchable-select__control--is-focused {
		@apply border-gray-300 hover:border-gamma-digital dark:border-white dark:hover:border-gamma-digital shadow-none;
	}

	.searchable-select-container .searchable-select__control--menu-is-open {
		@apply border-gamma-digital rounded-md;
	}

	.searchable-select-container .searchable-select__menu {
		@apply bg-gray-50 dark:bg-gray-800 border-2 border-gray-50 dark:border-gray-800;
	}

	.searchable-select-container .searchable-select__input {
		@apply border-none shadow-none ring-0;
	}

	.searchable-select-container .searchable-select__option {
		@apply text-black dark:text-white bg-gray-50 hover:bg-gamma-digital hover:text-white dark:bg-gray-800 dark:hover:bg-gamma-digital;
	}

	.searchable-select-container .searchable-select__indicator-separator {
		@apply bg-gray-400 dark:bg-white;
	}

	.searchable-select-container .searchable-select__input-container,
	.searchable-select-container .searchable-select__placeholder,
	.searchable-select-container .searchable-select__single-value {
		@apply text-black dark:text-white;
	}

	/* React-Select Custom CSS Classes - Searchable Selector - Error */
	.searchable-select-error-container .searchable-select-error__control {
		@apply w-full rounded-md shadow-sm sm:text-sm text-gray-800 dark:text-white dark:bg-gray-800 py-1;
	}

	.searchable-select-error-container .searchable-select-error__control--is-focused {
		@apply border-red-500 hover:border-red-500 shadow-none;
	}

	.searchable-select-error-container .searchable-select-error__control--menu-is-open {
		@apply border-red-500 rounded-md;
	}

	.searchable-select-error-container .searchable-select-error__menu {
		@apply bg-gray-50 dark:bg-gray-800 border-2 border-red-500;
	}

	.searchable-select-error-container .searchable-select-error__input {
		@apply border-none shadow-none ring-0;
	}

	.searchable-select-error-container .searchable-select-error__option {
		@apply text-black dark:text-white bg-gray-50 hover:bg-gamma-digital hover:text-white dark:bg-gray-800 dark:hover:bg-gamma-digital;
	}

	.searchable-select-error-container .searchable-select-error__indicator-separator {
		@apply bg-red-500;
	}

	.searchable-select-error-container .searchable-select-error__input-container,
	.searchable-select-error-container .searchable-select-error__placeholder,
	.searchable-select-error-container .searchable-select-error__single-value {
		@apply text-red-500;
	}
}
